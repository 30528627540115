/* INVOICES */
export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL";
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS";
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL";


export const GET_TESTS_IN_APPOINTMENTS = "GET_TESTS_IN_APPOINTMENTS";
export const GET_TESTS_IN_APPOINTMENTS_SUCCESS = "GET_TESTS_IN_APPOINTMENTS_SUCCESS";
export const GET_TESTS_IN_APPOINTMENTS_FAIL = "GET_TESTS_IN_APPOINTMENTS_FAIL";


export const DELETE_TESTS_IN_APPOINTMENTS = "DELETE_TESTS_IN_APPOINTMENTS";
export const DELETE_TESTS_IN_APPOINTMENTS_SUCCESS = "DELETE_TESTS_IN_APPOINTMENTS_SUCCESS";
export const DELETE_TESTS_IN_APPOINTMENTS_FAIL = "DELETE_TESTS_IN_APPOINTMENTS_FAIL";


export const GET_ADVINVOICE_DETAIL = "GET_ADVINVOICE_DETAIL";
export const GET_ADVINVOICE_DETAIL_SUCCESS = "GET_ADVINVOICE_DETAIL_SUCCESS";
export const GET_ADVINVOICE_DETAIL_FAIL = "GET_ADVINVOICE_DETAIL_FAIL";

/* PAYMENT */
export const UPDATE_PAYMENT_INFO = "UPDATE_PAYMENT_INFO";
export const UPDATE_PAYMENT_INFO_SUCCESS = "UPDATE_PAYMENT_INFO_SUCCESS";
export const UPDATE_PAYMENT_INFO_FAIL = "UPDATE_PAYMENT_INFO_FAIL";




